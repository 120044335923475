import type { RsPathType, Settings } from "~/types/common";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { utmStoreGetAll } from "~/lib/utm_store";
import type { ProgramWrapper } from "./program_wrapper";
import type { Lot } from "~/types/drupal_jsonapi";
import { arePricesDisplayed } from "./helper_program";
import { getChildrenOfType } from "./helper_lot";

/**
 * Returns the path part of a URL.
 *
 * - Input:  /de/jobs/berlin?foo=bar#baz
 * - Output: /de/jobs/berlin
 *
 * @param url
 */
export function removeSearchParams(url: string): string {
  return url.split(/[?#]/)[0];
}

/**
 * Given a URL, returns it with the query parameter r=s if the config for that path type it enabled.
 */
export function urlRs(
  type: RsPathType,
  url: string,
  settings?: Settings,
): string {
  let blackListedUrls = [];

  if (settings) {
    blackListedUrls = settings.solid_black_listed_urls;
  } else {
    const settingsContext = useDrupalSettingsContext();
    blackListedUrls = settingsContext.solid_black_listed_urls;
  }

  if (
    blackListedUrls.includes(removeSearchParams(url)) &&
    url.indexOf("r=d") === -1
  ) {
    return url + (url.indexOf("?") > -1 ? "&" : "?") + "r=d";
  }

  return url;
}

export const openEncodedUrl = function (encodedUrl: string) {
  const nl = [];
  for (let i = 0; i < encodedUrl.length; i++) {
    nl.push(String.fromCharCode(encodedUrl.charCodeAt(i) - 5));
  }

  const url = nl.join("");
  if (url.includes("altarea.com")) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
};

export const encodeStr = function (url: string) {
  const nl = [];
  for (let i = 0; i < url.length; i++) {
    nl.push(String.fromCharCode(url.charCodeAt(i) + 5));
  }
  return nl.join("");
};

/**
 * Opens a URL in a new tab with UTM parameters if they are present in the store.
 *
 * @param url string
 */
export const gotoUrlWithUtm = function (url: string) {
  if (hasCampaignParams()) {
    const params = utmStoreGetAll() as Record<string, string>;

    const query_string = Object.keys(params)
      .filter((k) => k !== "utm_created")
      .filter((k) => params[k])
      .map(function (key) {
        return key + "=" + params[key];
      })
      .join("&");

    url = url + "?" + query_string;
  }

  const win = window.open(url, "_blank");
  win!.focus();
};

export function hasCampaignParams() {
  const params = utmStoreGetAll();
  return params.utm_campaign || params.utm_source || params.utm_medium;
}

/**
 * Generate the URL to the Lmnp simulator with the lot's data.
 *
 * @param args Wrapper holing the program and the lots
 * @returns string The URL to the LMNP simulator with the lot's data.
 */
export function urlLmnpSimulator(args: {
  wrapper: ProgramWrapper;
  lot?: Lot;
  settings: Settings;
}) {
  const url =
    "/investir/les-outils-pour-preparer-mon-investissement/simulation-lmnp.html";

  const params = new URLSearchParams();
  params.append("TypeApproche", "1");

  if (args.lot) {
    args.lot.return_rate_vat_ex &&
      params.append(
        "TauxLoyer1",
        args.lot.return_rate_vat_ex.toString().replace(".", ","),
      );

    if (arePricesDisplayed(args.wrapper) && args.lot.price_vat_inc) {
      let price = args.lot.price_vat_inc;
      for (const parking of getChildrenOfType(
        args.lot,
        "parking",
        args.wrapper.lots,
      )) {
        price += parking.price_vat_inc ? parking.price_vat_inc : 0;
      }
      params.append("PrixLogement", price.toString());
    }

    if (arePricesDisplayed(args.wrapper) && args.lot.price_furniture_vat_ex) {
      params.append(
        "PrixMobilier",
        (args.lot.price_furniture_vat_ex * 1.2).toString().replace(".", ","),
      );
    }

    params.append("lot_id", args.lot.drupal_internal__id.toString());
  }

  params.append(
    "program_id",
    args.wrapper.program.drupal_internal__nid.toString(),
  );
  const dest = urlRs("simulator", url, args.settings);
  return dest.includes("?")
    ? `${dest}&${params.toString()}`
    : `${dest}?${params.toString()}`;
}

export function urlWithParams(url: string, params: Record<string, string>) {
  const urlParams = new URLSearchParams(params);
  const d = new URL(url, globalThis.location.href);
  // Get the existing params
  const existingParams = new URLSearchParams(d.search);
  // Merge the existing params with the new ones
  for (const [key, value] of urlParams) {
    existingParams.set(key, value);
  }
  // Set the new params
  d.search = existingParams.toString();
  return d.toString();
}
