import type { Metatag } from "~/types/drupal_jsonapi";
import { hashMapFromString } from "./transform";

export function getTrimesterFromDate(str: string): string | undefined {
  const date = new Date(str);

  switch (date.getMonth()) {
    case 0:
    case 1:
    case 2:
      return `1<sup>er</sup> trimestre ${date.getFullYear()}`;
    case 3:
    case 4:
    case 5:
      return `2<sup>ème</sup> trimestre ${date.getFullYear()}`;
    case 6:
    case 7:
    case 8:
      return `3<sup>ème</sup> trimestre ${date.getFullYear()}`;
    case 9:
    case 10:
    case 11:
      return `4<sup>ème</sup> trimestre ${date.getFullYear()}`;
    default:
      return undefined;
  }
}

export type TaxZone = "A" | "A bis" | "B1" | "B2";

export function isUrlKeyOfMapping(url: string, mapping: string): boolean {
  const map = hashMapFromString(mapping);
  return Object.keys(map).includes(url);
}

export function getDestinationUrlFromMapping(
  url: string,
  mapping: string,
): string {
  const map = hashMapFromString(mapping);
  return map[url];
}

export function getCanonicalPathFromMetaData(data: Metatag[]) {
  for (const item of data) {
    if (item.tag === "link" && item.attributes.rel === "canonical") {
      const url = new URL(item.attributes.href);
      return url.pathname;
    }
  }
  return undefined;
}

export function toDashedSafe(str: string): string {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-");
}
